import React, { useState,useEffect } from 'react'
import EnvironmentTableau from './environmentTableau';
import BackgroundImageOnLoad from 'background-image-on-load';

import MovingText from 'react-moving-text'
// reactstrap components
import { Container } from "reactstrap";


// core components

function IndexHeader() {
  const [ animationType, setAnimationType ] = useState("")
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

useEffect(() => {
  setInterval(() => {
    setIsDisplayed(true);
  }, 2000);
}, []);


useEffect(() => {
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);







  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  let pageHeader = React.createRef();
  const handleChainAnimation = () => {
    setAnimationType("fadeInFromTop")
  }




  
  return (
    <div style={{width:window.innerWidth, marginHorizontal:100}} >
    <div 
    className="page-header"
    data-parallax={true}
    ref={pageHeader}
    style={{
        width:window.innerWidth,
        backgroundImage:
        
      'url(' + require('assets/img/reps.jpg') + ')',
  
    }}
    >


<BackgroundImageOnLoad
            src={'https://unsplash.it/1200/310?random'}
            onLoadBg={() =>
              setAnimationType("fadeInFromTop")
            }
            onError={err => console.log('error', err)}
          />
        <div className="filter" />
        <Container>
        
          <div className="motto text-center" style={{marginTop:100,justifyContent:'center',alignItems:'center'}}>
          {isDisplayed?
            <MovingText
      onAnimationEnd={handleChainAnimation}
      type={animationType}
      duration="2000ms"
      timing="ease"
    delay="0s"
      iteration={1}>
      <h2 style={{fontWeight:'500'}}>Who are your representatives?</h2>
      <br/>
      <br/>
      <h7 style={{fontWeight:'500'}}> Review your MP & MLAs are, their political backgrounds and details all 
      in one report. Watch this space for more! </h7>
     
    </MovingText>
    :null}
    </div>
    
    </Container> 
        </div>
  
        <Container>
        <div className="motto text-center" style={{display: 'flex', overflow:'hidden',flexWrap:'wrap',justifyContent:'center',alignItems:'center',height:900,scrollSnapStop:scrollPosition< window.innerHeight?'always':'normal' }}>
        <iframe
       
       
       frameBorder="0" width={(window.innerWidth)} height={900}  src="https://public.tableau.com/views/RepresentativeDashboard-KnowYourNeta-V4/RepDashboard-Knowyourneta?:showVizHome=no&:language=en-US&:display_count=n&:origin=viz_share_link" ></iframe>,
 </div>
 </Container>
 </div>
 

      


    
           

  );
}

export default IndexHeader;