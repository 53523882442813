/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)



=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Health from "views/Health.js";
import Environment from "views/Environment.js";
import WomensSafety from "views/Womenssafety.js";
import Representatives from "views/Representatives.js";
import City from "views/City.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import About from "views/examples/About.js";

// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/health" render={(props) => <Health {...props} />} />
      <Route path="/environment" render={(props) => <Environment {...props} />} />
      <Route path="/women" render={(props) => <WomensSafety {...props} />} />
      <Route path="/representatives" render={(props) => <Representatives {...props} />} />
      <Route path="/city" render={(props) => <City {...props} />} />
      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route
        path="/pune"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/about"
        render={(props) => <About {...props} />}
      />
     
     
      <Redirect to="/pune" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
