/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Button,
} from "reactstrap";


// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer style={{backgroundColor:'black',opacity:0.85}} className="footer footer-black footer-white">
      <Container >
        <Row>
          <nav>
          <div style={{display:'flex',flexDirection:'column',marginTop:10}} >
          
              <Nav>
            <NavItem>
              <NavLink style={{color:'white',fontWeight:500}}
                to="../about" tag={Link}
              >
                <i style={{color:'white',fontWeight:500}}/>About Us
              </NavLink>
            </NavItem>
            </Nav>
            
          
          
            <Nav>
            <NavItem>
              <NavLink style={{color:'white',marginTop:-20}}
               
              >
                <i style={{color:'white'}}/>Contact Us : smartcitizenslab@gmail.com
              </NavLink>
            </NavItem>
            </Nav>
       
            
            
          </div>
           
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
            <a style={{marginRight:10}} href="https://google.com" target="_blank" rel="noreferrer">
          <img height={30} width={30}
            src={require('assets/img/fb.png')}
   
          />
        </a>
        <a style={{marginRight:10}} href="https://google.com" target="_blank" rel="noreferrer">
          <img height={30} width={30} 
            src={require('assets/img/instagram.png')}
   
          />
        </a>
        <a style={{marginRight:10}} href="https://google.com" target="_blank" rel="noreferrer">
          <img height={30} width={30}
            src={require('assets/img/twitter.png')}
   
          />
        </a>
        <a style={{marginRight:10}} href="https://google.com" target="_blank" rel="noreferrer">
          <img height={30} width={30}
            src={require('assets/img/linkedin.png')}
   
          />
        </a>
       
              
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
