/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundImage:
          'url(' + require('assets/img/pune.jpg') + ')'
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
          <div style={{backgroundColor: 'rgba(0, 0, 0, 0.4)',paddingLeft:20,paddingRight:20}} className="motto">
            
            <br/>
            <h7 style={{marginTop:20}}>We at Smart Citizens Lab, believe that the most
                important post in a democracy is the post of a citizen. Hence, it is essential 
                that the Indian citizen is well-informed, is able to deliberate effectively with fellow citizens and 
                has platforms to hold representatives accountable and influence public policy. 
                To this end, Smart Citizens Lab, aims to empower the citizens of Pune through it's open access platform, 
                'How is Pune/Kasa aahe Pune" so that they can ask this important question whenever and wherever necessary, while we do the hard work of 
                finding the answers.</h7> 
                <br/>
                <div style={{textAlign:'left'}}>
                <h7>Now citizens of Pune can review,</h7>
                <br/>
                
               <h7>1. The city’s budget, it’s expenditure on their priorities whenever they need! Be it before filing an RTI, atttending a wards committee meeting or going to vote in the city’s elections. </h7> 
               <br/>
               
<h7>2. Pune's MPs & MLAs and their backgrounds, their performance on key indicators </h7>
<br/>
<h7>3. The health infrastructure in Pune, including MMR/IMR and trends in communicable diseases and how Pune's Health outcomes compare with other Indian cities on SDG 3.</h7>
<br/>
{/* <h7>4. The state of crime against women and their justice. </h7>
<br/> */}
<h7>5. The quality of Pune's air, water and forests and Pune's performance on SDGs 6, 12,13 & 15. </h7>
<br/>
<br/>
<h7>Please feel free to reach to us with suggestions on how we can improve this platform. This platform is of the people, by the people and for the people of Pune.
    Reach out to us at smartcitizenslab@gmail.com 
</h7>
<br/>
</div>
                
            <br />
           
         
          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
