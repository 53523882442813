/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
} from "reactstrap";

function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      console.log('abcd',document.documentElement.scrollTop||document.body.scrollTop)
      if (
        document.documentElement.scrollTop > 60 ||
        document.body.scrollTop > 60
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
      style={{backgroundColor:'#333333',opacity:0.85, maxHeight:100}}
    >
      <Container style={{display:'flex',justifyContent:'flex-start', alignItems:'center'}}>
        <div style={{backgroundColor:'white'}}  >
       
              <img src={require('assets/img/Transparent2.png')} width={100} height={100}  />
               
          {/* <NavbarBrand
          
            data-placement="bottom"
            to="/about"
            target="_blank"
            title="About"
            tag={Link}
          >
            <div style={{color:'white'}}>
            About
            </div>
          </NavbarBrand> */}
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
       
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
          
              
            
           
          <NavItem>
              <NavLink style={{color:'white'}} to="/home" tag={Link}>
                <i className="nc-icon nc-layout-11" style={{color:'white'}} /> Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink style={{color:'white'}} to="/city" tag={Link}>
                <i style={{color:'white'}} className="nc-icon nc-money-coins" /> City Budget
              </NavLink>
            </NavItem>
             <NavItem >
              <NavLink style={{color:'white'}}
                 to="/representatives" tag={Link}
              >
                <i style={{color:'white'}} className="nc-icon nc-box" /> Representatives
              </NavLink>
            </NavItem> 
            {/* <NavItem>
            <NavLink style={{color:'white'}}
                to="/health" tag={Link}
              >
                <i style={{color:'white'}} className="nc-icon nc-ambulance" /> Health
              </NavLink>
              
            </NavItem> */}
            <NavItem>
            <NavLink style={{color:'white'}}
                  to="/women" tag={Link}
              >
                <i style={{color:'white'}} className="nc-icon nc-alert-circle-i" /> Womens safety
              </NavLink>
              
            </NavItem>
            <NavItem >
            <NavLink style={{color:'white'}}
               to="/environment" tag={Link}
              >
                <i style={{color:'white'}} className="nc-icon nc-planet" /> Environment
              </NavLink>
              
            </NavItem>
            
           
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default ExamplesNavbar;
