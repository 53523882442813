/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState,useEffect } from 'react'
import MovingText from 'react-moving-text'
import BackgroundImageOnLoad from 'background-image-on-load';
// reactstrap components
import { Button, Container } from "reactstrap";
//import { Carousel } from 'react-carousel-minimal';
import { Carousel } from "react-responsive-carousel";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";





// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

 

 
  const [ animationType, setAnimationType ] = useState("")
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  
  const history = useHistory();

useEffect(() => {
  setInterval(() => {
    setIsDisplayed(true);
  }, 2000);
}, []);

useEffect(() => {
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);
const marginTop = scrollPosition > window.innerHeight ? (window.innerHeight)/2.2 : "0";

console.log(isDisplayed)
 
  const handleChainAnimation = () => {
    setAnimationType("fadeInFromTop")
  }

  const data = [
    {
      image: require("../../assets/img/pune3.jpg"),
      caption: "How is Pune / कसा काय पुणे ?",
      link:"/pune"
    },
   
    {
      image: require("assets/img/Budget_image_v2.jpg"),
      caption: "How is PMC Spending it's Budget?",
      link:"/city"
    },
    {
      image: require("assets/img/crime_safety_v3.jpg"),
      caption: "Is Pune Safe for Women?",
      link:"/women"
    },
    {
      image: require("assets/img/reps.jpg"),
      caption: "Who are Pune's MP's and MLA's? ",
      link:"/representatives"
    },
    // {
    //   image: require("assets/img/Health_image.jpg"),
    //   caption: "How is Public Health in Pune?",
    //   link:"/health"
    // },
    {
      image:require("assets/img/pollution_image_v2.jpg"),
      caption: "Is Pune's Environment Sustainable?",
      link:"/environment"
    },
    
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  const imageClick = (e) => {
    console.log('Click!!!!',data[e].link);
    history.push(data[e].link)

  }  
  

  return (
    <>
    <div style={{}}>
      <div
        
        className="page-header"
        
        data-parallax={true}
        ref={pageHeader}
        style={{margin:0}}
      >
 
        
        
        
          
       
          {/* <Carousel
            data={data}
            time={3500}
            width={window.innerWidth}
            height={window.innerHeight}
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={false}
            slideNumberStyle={slideNumberStyle}
            captionPosition="center"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover" 
            style={{
              textAlign: "center",
              width: window.innerWidth,
              
             
            }}
          /> */}

<div style={{width:window.innerWidth}}>
        <Carousel interval={2500} swipeable={false} stopOnHover={false} autoPlay infiniteLoop showArrows={false} showThumbs={false} onClickItem={imageClick}>
          {data.map((item) => (
            <div>
            <article 
            style={{ cursor:'pointer', filter:'blur(0.3px)', WebkitFilter:'blur(0.3px)', backgroundImage:  'url(' + item.image + ')',backgroundSize:'cover', height:window.innerHeight,margin:0}}
            >
              <div className="filter" />
              </article >
            
            <div style={{top: '50%', textAlign:'center',
  left: '50%',position:'absolute',transform:'translate(-50%, -50%)'}}>
               <h4 style={{fontWeight:'bold',color:'white'}}>{item.caption}</h4>
               </div>
               </div>
            
          ))}
        </Carousel>
        
        </div>


        







           
           
            
           
         
         
  
      </div>
      
      <div style={{display:'flex', alignItems:'center',justifyContent:'space-around',marginHorizontal:40,marginTop:20
 }}>
        <div style={{fontSize:30, flex:0.5, fontFamily:'monospace',fontSizeAdjust:'initial',justifyContent:'flex-start',marginLeft:30}}> YOU ARE THE MOST IMPORTANT PERSON IN THIS DEMOCRACY, DEAR CITIZEN !</div>
        <div style={{justifyContent:'flex-end',flex:0.5}}> <iframe height={300} width={'100%'} frameBorder={0} src="https://embed.lottiefiles.com/animation/108894"></iframe></div>
      
        </div>

        <div style={{display:'flex', alignItems:'center',justifyContent:'space-around',marginHorizontal:40,marginTop:20
 }}>
        
        <div style={{justifyContent:'flex-start',flex:0.5}}> <iframe height={300} width={'100%'}  frameBorder={0}  src="https://embed.lottiefiles.com/animation/99340"></iframe></div>
        <div style={{fontSize:30, flex:0.5, fontFamily:'monospace',fontSizeAdjust:'initial',justifyContent:'flex-end',marginRight:30}}> NOW KEEP TRACK OF YOUR FAVOURITE CITY LIKE NEVER BEFORE!</div>
        </div>
      </div>


      <div style={{display:'flex', alignItems:'center',justifyContent:'space-around',marginHorizontal:40,marginTop:20
 }}>
        <div style={{fontSize:30, flex:0.5, fontFamily:'monospace',fontSizeAdjust:'initial',justifyContent:'flex-start',marginLeft:30}}> NOW YOU CAN BE SMARTER AT CHAI TAPRI DISCUSSIONS, DEALING WITH PUBLIC OFFICIALS AND VOTING DURING ELECTIONS</div>
        <div style={{justifyContent:'flex-end',flex:0.5}}> <iframe height={300} width={'100%'} frameBorder={0} src="https://embed.lottiefiles.com/animation/76226"></iframe></div>
      
        </div>
      {/* <Carousel
      responsive={responsive}
      
  swipeable={true}
  draggable={false}
  showDots={false}
 
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  centerMode={true}
  autoPlaySpeed={500}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={100}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
  <div> <img height={400} width={'100%'}  src={require('assets/img/pune.jpg')}/></div>
  <div> <img height={400} width={'100%'} src={require('assets/img/Budget_image.jpg')}/></div>
  <div> <img height={400} width={'100%'} src={require('assets/img/pune.jpg')}/></div>
  <div> <img height={400} width={'100%'} src={require('assets/img/Budget_image.jpg')}/></div>
</Carousel> */}
<div style={{flexDirection:'row',flex:1}}>
<div style={{ padding: 30,width:'60%'


}} >
  

 

    
  </div>
  </div>
  
    </>
  );
}

export default LandingPageHeader;
